<div class="static-page">
    <div class="static-page__container">
        <div class="static-page__logo-mrdm shared__logo-mrdm"></div>
        <div class="static-page__title">{{ ".Title" | lgTranslate }}</div>
        <p class="static-page__message" [innerHTML]="'.Text' | lgTranslate"></p>
        <div class="static-page__links">
            @if (_myAccountUrl) {
            <a [href]="_myAccountUrl"> {{ "APP._Shared.StaticPages.MyAccount" | lgTranslate }} </a>
            } @if (_portalUrl) {
            <a [href]="_portalUrl"> {{ "APP._Shared.StaticPages.CodmanPortal" | lgTranslate }} </a>
            }
            <a href="https://support.mrdm.com/">
                {{ "APP._Shared.StaticPages.SupportWebsite" | lgTranslate }}
            </a>
        </div>

        @if (_portalUrl) {
        <div class="static-page__redirect">
            {{ "APP._Shared.StaticPages.Redirect" | lgTranslate : { seconds: _secondsToRedirect$ |
            async } }}
        </div>
        } @if (_userProfile$ | async; as userProfile) {
        <div class="static-page__logout-box">
            <span class="static-page__login-info">
                {{ "APP._Shared.StaticPages.LoginInfo" | lgTranslate : userProfile }}
            </span>
            <lg-button
                buttonClass="button--condensed button--primary"
                textLc="FW.LOGOUT"
                (click)="_logout()"
            ></lg-button>
        </div>
        }
    </div>
</div>
