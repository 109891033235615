import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, Subscription, of, concat } from "rxjs";
import { map } from "rxjs/operators";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgFilterSet, IComboFilter2WrappedId } from "@logex/framework/lg-filterset";

import { RegistryService } from "./registry.service";
import { IFilterGroup, IRegistryFilter } from "./registry.types";
import { SettingsService } from "./settings.service";
import { defaultRegistry } from "@codman/patients/data-access-base";
import { FilterFactory, FilterFactoryCreator } from "@codman/shared/util-filters";

export interface FilterCountsResponse {
    [filterOption: string]: number;
}

export type IFilters = Array<[string, { [id: number]: string }]>;

export interface ActiveFilterGroup {
    name: string;
    filters: Array<{
        name: string;
        selectedOptions: {
            [key: number]: string;
        };
    }>;
}

const separators = {
    filters: "&",
    nameValue: "=",
    values: ",",
};

@Injectable({
    providedIn: "root",
})
export class FiltersService {
    private _registryService = inject(RegistryService);
    private _lgTranslateService = inject(LgTranslateService);
    private _filterFactory = inject(FilterFactory);
    private _httpClient = inject(HttpClient);
    private _settingsService = inject(SettingsService);

    _filterDefinition: LgFilterSet = {} as LgFilterSet;
    private _filtersSelected$ = new BehaviorSubject<boolean>(false);
    private _filtersChangedSubscription: Subscription = Subscription.EMPTY;

    readonly _sorterOptions = [
        {
            property: "name",
            name: this._lgTranslateService.translate(
                "APP._Shared.SortingOptions.AlphabeticallyAsc",
            ),
            icon: "icon-alphabetically",
            title: this._lgTranslateService.translate(
                "APP._Shared.SortingOptions.AlphabeticallyAsc",
            ),
        },
        {
            property: "name",
            name: this._lgTranslateService.translate(
                "APP._Shared.SortingOptions.AlphabeticallyDesc",
            ),
            icon: "icon-alphabetically-desc",
            title: this._lgTranslateService.translate(
                "APP._Shared.SortingOptions.AlphabeticallyDesc",
            ),
            reverse: true,
        },
        {
            property: (item: any) => item.data,
            name: this._lgTranslateService.translate("APP._Shared.SortingOptions.ValueAsc"),
            icon: "icon-sort-asc",
            title: this._lgTranslateService.translate("APP._Shared.SortingOptions.ValueAsc"),
        },
        {
            property: (item: any) => item.data,
            name: this._lgTranslateService.translate("APP._Shared.SortingOptions.ValueDesc"),
            icon: "icon-sort-desc",
            title: this._lgTranslateService.translate("APP._Shared.SortingOptions.ValueDesc"),
            reverse: true,
        },
    ];

    get filterDefinition(): LgFilterSet {
        return this._filterDefinition;
    }

    get filtersSelected$(): Observable<boolean> {
        return this._filtersSelected$.asObservable();
    }

    selectFilters(selected = true): void {
        this._filtersSelected$.next(selected);
    }

    setupFilterDefinition(subset: string, dashEnv: string): LgFilterSet {
        if (this._filtersChangedSubscription != null) {
            this._filtersChangedSubscription.unsubscribe();
        }

        const filterFactory = this._filterFactory.define();
        this.addFilters(filterFactory, subset, dashEnv);
        this._filterDefinition = filterFactory.create(this);

        this._filtersChangedSubscription = this._filterDefinition.onChanged.subscribe(() =>
            this._refilter(),
        );
        return this._filterDefinition;
    }

    addFilters(filterFactory: FilterFactoryCreator, subset: string, dashEnv: string): void {
        if (filterFactory == null || filterFactory.addMappedStringCombo2Filter == null) {
            return;
        }
        const filters = this._registryService.getSubsetConfig(subset)?.filters;

        if (filters)
            for (const filterGroup of filters) {
                this._addFilterGroup(filterFactory, filterGroup, dashEnv);
            }
    }

    getFiltersQueryString(): string {
        const activeFilters = this.getActiveFilters();

        const filterStrings = activeFilters.map(([filterName, selection]) => {
            return this._getFilterQueryString(filterName, Object.keys(selection));
        });

        return filterStrings.join(separators.filters);
    }

    getActiveFilterGroups(): ActiveFilterGroup[] {
        const activeFiltersLookup = this.getActiveFilters().reduce(
            (lookup, [filterName, selection]) => {
                lookup[filterName] = Object.values(selection);
                return lookup;
            },
            <{ [filterName: string]: string[] }>{},
        );

        return this._filterDefinition.visibleGroups
            ?.map(group => ({
                name: group.name,
                filters: group.filters
                    ?.map(filter => ({
                        name: filter.name ?? "",
                        selectedOptions: activeFiltersLookup[filter.id],
                    }))
                    .filter(filter => filter.selectedOptions != null),
            }))
            .filter(group => group.filters.length > 0);
    }

    getActiveFilters(): Array<[string, { [id: number]: string }]> {
        const filters = this._filterDefinition && this._filterDefinition.filters;
        if (filters == null) {
            console.warn("Attempting to get filters query string when no filters are defined");
            return [];
        }

        return Object.entries(filters).filter(([filterName]) =>
            this._filterDefinition.isActive(filterName),
        );
    }

    getFilterCount(filterId: string, dashEnv: string): Observable<FilterCountsResponse> {
        const subset = this._settingsService.subset;
        const subsetConfig = this._registryService.getSubsetConfig(subset);
        const subsetApiName = subsetConfig ? subsetConfig.apiName : null;
        const baseUrl = `https://${defaultRegistry}${dashEnv}.valuebase.nl/patientslikeme/`;
        if (subsetApiName) {
            const filtersQueryString = this.getFiltersQueryString();
            return this._httpClient.get<FilterCountsResponse>(
                `${baseUrl}${subsetApiName}/treatments/filtercount/${filterId}` +
                    "?" +
                    filtersQueryString,
            );
        } else {
            return of({});
        }
    }

    private _addFilterGroup(
        filterFactory: FilterFactoryCreator,
        filterGroup: IFilterGroup,
        dashEnv: string,
    ): void {
        filterFactory.startGroup(filterGroup.groupNameLc);
        Object.entries(filterGroup.filters).forEach(([filterId, filter]) => {
            this._addFilter(filterFactory, filterId, filter, dashEnv);
        });
    }

    private _addFilter(
        filterFactory: FilterFactoryCreator,
        filterId: string,
        filter: IRegistryFilter,
        dashEnv: string,
    ): void {
        filterFactory.addMappedNumberCombo2Filter(filterId, {
            name: this._lgTranslateService.translate(filter.nameLc),
            wide: true,
            showSelected: 5,
            maxRows: 3,
            showDataCounts: true,
            source: () =>
                concat(
                    of(
                        filter.options.map(
                            option => <IComboFilter2WrappedId<number>>{ id: option.id, data: null },
                        ),
                    ),
                    this.getFilterCount(filterId, dashEnv).pipe(
                        map(counts => {
                            return filter.options.map(
                                option =>
                                    <IComboFilter2WrappedId<number>>{
                                        id: option.id,
                                        data: counts[option.id] ?? 0,
                                    },
                            );
                        }),
                    ),
                ),
            optionName: (id: number) => {
                // TODO: consider using options lookup
                const option = filter.options.find(option => option.id === id);
                return option ? this._lgTranslateService.translate(option.nameLc) : "";
            },
            showSorter: true,
            sorterOptions: this._sorterOptions,
        });
    }

    private _refilter(): void {
        //
    }

    private _getFilterQueryString(
        filterName: string,
        filterValues: Array<number | string>,
    ): string {
        const filterValuesQuery = filterValues.join(separators.values);
        return filterName + separators.nameValue + filterValuesQuery;
    }
}
